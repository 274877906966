/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useEffect } from 'react';

export default function Layout({ children }) {
	// useEffect(() => {
	// 	window.addEventListener('resize', () => {
	// 		document
	// 			.querySelector(':root')
	// 			.style.setProperty('--vh', window.innerHeight + 'px');
	// 	});
	// }, []);

	return (
		<Box
			sx={{
				width: 'calc(100vw - 2rem)',
				maxWidth: '33.3333rem',
				margin: '0 auto',
			}}
		>
			{children}
		</Box>
	);
}
